.async-downloader-toast-wrapper {
  .toast {
    max-width: none;
    width: 420px;

    .toast-body > .row > div:first-child svg {
      display: block;
    }
  }

  .spinner-icon,
  .fa-check-circle,
  .fa-exclamation-triangle {
    width: 3em;
    height: 3em;
  }
}
