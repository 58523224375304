.secret-toggle {
  svg {
    cursor: pointer;
  }
}

.secret-wrapper {
  .secret-value,
  .secret-toggle {
    line-height: 24px;

    & > * {
      vertical-align: middle;
    }
  }

  .secret-value {
    position: relative;
  }

  .secret-visible {
    position: absolute;
    left: 0;
    top: 0;
  }

  .secret-hidden {
    visibility: hidden;
  }

  .secret-toggle {
    margin-right: 15px;
    width: 20px;
    display: inline-block;
  }
}
