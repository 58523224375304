.rdtPicker {
  width: 100%;
}

.datetime-filter-modal-body {
  .btn-sm {
    padding: 0.25rem 0.6875rem;
    line-height: 2;
  }
}
