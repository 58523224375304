h1 {
  font-size: 10px;
}

.selected {
  text-decoration: underline;
}

input.number-filter-input {
  width: 80px;
  display: inline-block;
  margin-right: 10px;
  height: 38px;
}

select.select-filter {
  height: 38px !important;
  margin-bottom: 3px;
}

input.text-filter {
  height: 38px;
  margin-bottom: 3px;
}

.ril__payloadButton {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAABT0lEQVQ4ja2UMUoEMRSGv38YFpFlC4tFPIBYeAARr2ClpWBjbyUewEqsLLyP1R7AA1hYbyHLsojF/Baa5WVMxi18ECZ5ee/Ln+RNZAyAO29JOgC2qdsLYjkwT5s6kk5sP0ka206+GPtp+1TWHjCrgdvQ3wX2EyhBIxAYAWfAJeYaMe8HNTXpFWjKOQfuMJMq0Ha2xTgugEe2r4DHPnQNHEjO/Lbntl8lvQFHwIU7rzltP6l3EevFbDeSDm0/APchvpPUAN0vYFSSzjB8W+BB0o3tLqR8ALfAcwYcOsNgU9vTqBxY2Z4I5VuOt9oHx29pJzE+K5s40e8PnG3mq9bhkLq0QKkiMmCtZJK6msoqsLS1vpIStAqMKmPJpHFqGwP7kJKiv/6oosISZBNfBrS9AFZx9dLtFmz5075jwou9AxwD44Gnq2TvwEyNFhnwv+wLacfEE3EDVZwAAAAASUVORK5CYII=') no-repeat center;
}

.ril__resultButton {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAABk0lEQVQ4ja2UvUoDURCFv3NZgoRgYauInWApFrZGsFUrwV6wEiGISAofIS8ilj6DhXZiJWIpYiFiIUFyLLzRuzd3tXGa3Z2fM+fMsCNjADAt2/OS2pRtZPtOQe8NcQCq5H0VGACdNME2krA9BHYwM7YvFfTxF+A8sJwDSQJA0tD2lO09SduYU8RbDhiaqEdWJX9le9/2AabVCDhmVPrOgSW1JfWBfg4akqRicaHBK/AIvNreBbbSvKqhKGeE7cDXnHvAUUKitpwqLx4zlDQCRpmCge1HSQEIkcAL5gRxVQPMZjYEzoHrQjzYXpe0EZk/S5qeYJhuNW5yWdJcxnD8XEhyawprkhPQkaSblGHCNABrwFxp3hMzjPYRwS7yQGw6K6lbWuLElqNNAadAP42NFUhq5f4iYBJ4B46Bs6wRccM94DCrKUuOHStgE1jMi+IMV0rzK0qOsiqgK6lbYEjuS23iOJQGnV6d33w1hrafJN3b7iS+4tXJmj4Az9+NkovdBpaA4sVu+s+BF+AWff3TP4D/ZJ9FZ9b6wZvNvwAAAABJRU5ErkJggg==') no-repeat center;
}

.ril__infoButton {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAABH0lEQVQ4ja2UvUpDQRBGzxdSBBQJwUKiRR5ArHwlW0tbW9/AJ7Cw9AlsBd9ALEVSJanUQvZYmAshZG+uP7PVzu6emdkZvohUTcbAaMXzQljUHwC2rFLKjWopxVKK6nnbfZF+azQYqyRp9odb7rcDV0CdbVuGd0B/mWUB7rcB09qUX1jvX2nUSpYj4EwdNv+oliS3hIefA2GiXiQZNI4kqPOQVuDGktVekh5QVv1JdtpgVWCSZ+AKmG4DdAISXgmXwFPj0m7T0LnLXYe8E7BrdnWg9JEDYLAG20VG2JLIRs3QE/VRfV9Tm7l6re7X1KYWaQScqgP4/r/lHA6BY2DvZyXDGzCrnM2AjxpwszjIQJ0kaZRmtTGLJFPCZ3fgH+wLfLu7ipPEmigAAAAASUVORK5CYII=') no-repeat center;
}

.badge-good {
  background-color: rgb(60, 179, 113);
  color: white;
}

.badge-bad {
  background-color: #ff6384;
  color: white;
}

.badge-not-set {
  background-color: #17a2b8;
  color: white;
}

.bg-good {
  background-color: rgb(60, 179, 113);
}

.bg-bad {
  background-color: #ff6384;
}
