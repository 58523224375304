.reset-expansion-style {
  padding: 0 !important;
}

.row-expansion-style {
  background-color: lightgoldenrodyellow;
}

.nowrap {
  white-space: nowrap;
}

.expand-cell-header {
  cursor: pointer;
}

.expand-cell {
  min-width: 25px;
  cursor: pointer;
}

.expand-collapse-toggle {
  cursor: pointer;
  width: 26px;
}
