.datetime-filter-table-header {
  outline: none;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-bottom: 1px;

  &:hover {
    color: #888;
  }
}

.datetime-filter-table-header-value {
  font-weight: normal;
  font-size: 0.8rem;
}
