$shadow-intensity: 0.2;
$shadow: 2px 2px 2px rgba(0, 0, 0, $shadow-intensity);

.plot-point-hover {
  position: absolute;
  z-index: 1000;
  color: #000;
  font-size: 12px;
  line-height: 14px;
  margin-left: 12px;
  transform: translateY(-50%);
  overflow: visible;
  box-shadow: $shadow;
  width: 800px;
  max-width: calc(50vw - 60px);

  .plot-point-hover-inner {
    width: 100%;
    overflow-y: auto;
    max-height: 350px;
    position: relative;
    z-index: 10;
    background: #f4f4f4;
  }

  .progress-bar-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #f4f4f4;
    padding: 4px;
  }

  .progress-bar {
  }

  .left-hover-arrow,
  .right-hover-arrow {
    display: none;
    position: absolute;
    top: 50%;
    margin-top: -7px;
    z-index: 9;
    left: -10px;
    filter: drop-shadow($shadow);
  }

  &.open-left {
    transform: translateX(-100%) translateY(-50%);
    margin-left: -22px;

    .left-hover-arrow {
      width: 10px;
      display: block;
      left: auto;
      right: -10px;
      filter: drop-shadow($shadow);
    }
  }

  &.open-right {
    .right-hover-arrow {
      display: block;
      filter: drop-shadow(0 2px 2px rgba(0, 0, 0, $shadow-intensity));
    }
  }

  table {
    margin: 10px;
    width: calc(100% - 20px);
    table-layout: fixed;

    td {
      .ellipsis-tooltip-container, .cell-inner {
        padding: 3px 10px 3px 4px;
      }

      .btn-tiny {
        font-size: 12px;
        padding-top: 0;
        padding-bottom: 0;
        background: none;
        border: none;

        &:focus {
          box-shadow: none;
        }
      }

      &:nth-child(5n+3) {
        background: none;
        width: 20px;
      }

      &:nth-child(5n+1) {
        background: none;
      }

      &.spacer {
        width: 20px;
        background: none !important;
      }

      &.bg-good,
      &.bg-bad {
        color: #fff;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    tr.odd td:not(.bg-bad):not(.bg-good):not(:nth-child(3n+3)):not(.no-bg) {
      background: rgba(#ddd, 0.4);
    }
  }

  .has-errors {
    border: 2px dotted orange;

    &.field-name {
      border-right-width: 0;
    }

    &.main-field {
      border-color: red;
    }
  }
}
