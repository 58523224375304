@import "../../variables";

.input-group-addon {
  border-left-width: 0;
  border-right-width: 0;
}

.input-group-text:first-child {
  border-left-width: 1px;
}

.input-group-text:last-child {
  border-right-width: 1px;
}

.react-bootstrap-table {
  overflow-x: auto !important;
  overflow-y: visible;
}

td.anomaly-field {
  border: 3px solid black inset;
}

td.bg-multivariate-anomaly {
  background-color: lightyellow;
}

.react-contexify__item:not(.react-contexify__item--disabled).datagrid-menu-item-good > .react-contexify__item__content strong {
  color: rgb(60, 179, 113);
}

.react-contexify__item:not(.react-contexify__item--disabled).datagrid-menu-item-good > .react-contexify__item__content span {
  font-weight: bold;
}

.react-contexify__item:not(.react-contexify__item--disabled).datagrid-menu-item-good:hover > .react-contexify__item__content {
  color: white;
}

.react-contexify__item:not(.react-contexify__item--disabled).datagrid-menu-item-bad > .react-contexify__item__content strong {
  color: #ff6384;
}

.react-contexify__item:not(.react-contexify__item--disabled).datagrid-menu-item-bad > .react-contexify__item__content span {
  font-weight: bold;
}

.react-contexify__item:not(.react-contexify__item--disabled).datagrid-menu-item-bad:hover > .react-contexify__item__content {
  color: white;
}

.bootstrap-table-wrapper {
  position: relative;

  .table {
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;

    thead th {
      padding: 0;

      label {
        margin-bottom: 0;
      }
    }
  }

  td {
    position: relative;

    & > span {
      user-select: text;
      display: inline-block;
      margin-left: 2px;
    }

    & > span:not(.badge) {
      display: block;
      position: relative;
    }

    &.selectable-cell {
      user-select: none;

      & > .datagrid-cell {
        outline: none;

        &::before {
          content: "";
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          position: absolute;
          z-index: 0;
        }

        &.in-rectangular-selection {
          &::before {
            width: calc(100% + 1px);
            height: calc(100% + 1px);
            left: 0;
            top: 0;
            background: rgba($typo-blue, 0.4);
          }
        }

        &.selected {
          text-decoration: none;

          &:not(.cursor)::before {
            background: rgba($typo-blue, 0.4);
          }

          &.cursor::before {
            border: 1px solid #0154ff;
            background: rgba($typo-blue, 0.4);
          }
        }

        &:not(.selected) {
          &.cursor::before {
            content: "";
            display: block;
            position: absolute;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            left: -1px;
            top: -1px;
            border: 2px solid $typo-blue;
          }
        }

        & > .cell-content {
          position: relative;
          z-index: 2;
          user-select: text;
          cursor: default;
        }
      }
    }

    .cell-spacer {
      user-select: none;
    }
  }

  .filter-label {
    padding: 5px 10px 5px 4px;
    min-width: 70px;
  }

  .filter.select-filter {
    height: 31px;
    font-size: 14px;
  }

  .datagrid-header {
    padding: 6px 5px;
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    &.sortable:hover {
      color: #888;
    }
  }

  .react-bootstrap-table-pagination {
    .dropdown-item.active,
    .dropdown-item:active {
      background-color: #6c757d;
    }

    & > div {
      display: flex;
      flex-direction: row;
    }

    div:last-child {
      justify-content: flex-end;
    }

    .react-bootstrap-table-pagination-total {
      margin-left: 20px;
      line-height: 38px;
    }
  }

  .react-bootstrap-table tbody td:not(.actions-column) {
    padding: 0.6rem 0.3rem;
  }

  th.tag-header {
    input[type="text"] {
      min-width: 100px;
      width: auto;
    }
  }

  .react-bootstrap-table-editing-cell {
    padding: 0.44rem 0.3rem 0 !important;
  }

  .typeahead-cell,
  .react-bootstrap-table-editing-cell {
    cursor: text;

    &:hover {
      background: #ccc;
    }
  }

  &.two-row-headers {
    .datagrid-header {
      min-height: 61px;
    }
  }

  &.with-header-separator {
    .datagrid-header {
      border-top: 1px solid #dee2e6;
    }
  }

  .no-results-container {
    width: 100vw;
    top: 1000px;
    z-index: 1000;
  }

  &.no-results {
    .no-results-spacer {
      height: 40px;
    }
  }

  .no-results-label {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 187px;
    z-index: 10;
  }
}

body.shift-key-pressed,
body.ctrl-key-pressed {
  .datagrid-wrapper td.selectable-cell .datagrid-cell > .cell-content {
    user-select: none !important;
  }
}

.datagrid-header-wrapper {
  & > * {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.datagrid-wrapper {
  position: relative;

  .datagrid-overlay {
    position: absolute;
    left: 0;
    top: 90px;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
  }
}
