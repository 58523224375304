#statistics-tabs-tab-first.nav-link,
#statistics-tabs-tab-second.nav-link,
#statistics-tabs-tab-third.nav-link {
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 1;
}

@media (min-width: 1199.98px) {
  .user-error-percentage {
    padding-right: 4vw;
  }
}

@media (max-width: 1199.98px) {
  .user-error-percentage {
    padding-right: 8vw;
  }
}
