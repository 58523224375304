@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,700,700i&display=swap');
@import './BootstrapVariables.scss';
@import '~bootstrap/scss/bootstrap';

body {
  padding-bottom: 40px;
  font-family: 'Roboto', sans-serif;
}

.App .navbar-brand {
  font-weight: bold;
}

.App-logo {
  animation: spin infinite 20s linear;
  height: 80px;
}

nav {
  margin-bottom: 20px;
}

.badge {
  user-select: none;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.react-contexify {
  z-index: 100;
}

.limited-width {
  max-width: 1000px;
}

.limited-width-md {
  max-width: 1300px;
}

.editableFieldBackground {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

td.position-relative:hover {
  .editableFieldBackground {
    background: #ddd;
    cursor: text;
  }
}

.editableFieldContent {
  position: relative;
  z-index: 1;

  span.badge {
    cursor: text;
  }
}

.toast-wrapper-center-top {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 20px;

  .toast {
    max-width: none;
    width: 450px;
    background: #fff;
  }
}

.react-bootstrap-table.table-responsive {
  margin-bottom: 1rem;

  table {
    position: relative; // Fixes overflow issue on Chrome
    table-layout: auto;
    margin-bottom: 0;
  }

  th { outline: none; }

  tbody {
    td { min-height: 50px; }

    td.actions-column {
      padding: 0.48rem 0.3rem;
    }

    td:not(.actions-column) {
      padding: 0.7rem 0.3rem 0.7rem 0.3rem;
    }
  }
}

ul.pagination {
  margin-bottom: 0;

  .page-link:focus {
    box-shadow: none;
  }

  .page-item.active .page-link {
    border-color: #6c757d;
  }
}

.react-bs-table-sizePerPage-dropdown {
  .dropdown-item > a {
    display: block;
    color: #212529;

    &:hover {
      text-decoration: none;
      color: #212529;
    }
  }
}

#tag-editor {
  .dropdown-item {
    font-size: 0.85rem;
    padding: 0.25rem 0.7rem;
  }
}

.text-link {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.form-check {
  padding-left: 1.4rem;
}

.form-check-input {
  margin-left: -1.4rem;
}

.small-centered-container {
  max-width: 470px;
}

.no-wrap {
  white-space: nowrap;
}

.custom-control.custom-switch {
  &,
  & > input,
  & label {
    cursor: pointer;
  }
}

.btn-tiny {
  padding: 0.1rem 0.3rem;
}
